<template>
 <main class="wrapper">
    <NavLogo class="logo"/>
    <div class="item image">img</div>
    <div class="item sign-in">
      <router-view />
      <footer><Footer /></footer>
    </div>
 </main>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";

import NavLogo from "@/views/_layout/_NavLogo.vue";
import Footer from "@/views/_layout/_Footer.vue";
import router from "@/router";
import Constant from "@/helpers/Constant";
import { useStoreSite } from '@/stores/store.site'



onMounted(async () => {

});
</script>


<style lang="scss" scoped>
.wrapper {
  display: flex;
  background-color: white;
  .item {
    flex: 1 100%;
  }
}

.logo {
  position: fixed;
  top:10px;
}

.image {
  display: none;
}

.sign-in {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

footer {
  align-self: flex-end;
  flex: 1 100%;
  margin: 0;
  padding: 3px 0;
}

@media screen and (min-width: $break-medium) {
  
  footer {
    padding: 10px 0;
  }

  .logo {
    display: none;
  }
  .image {
    display: block;
    background-image: url("@/assets/img/login-4.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
</style>
