import env from '@/helpers/Environment';
import { callApi, callApiUrl } from '@/services/WebApi';

/*
const initSignInMobile = (data) => {
  return callApi('POST', `account/init-sign-in-mobile`, data)
};
const submitOtp = (data) => {
  return callApi('POST', `account/sign-in-verify-mobile-otp`, data)
};
*/

const oidcLogin = (data) => {
  const url = env.API_URL + "/" + "auth/oidc-login";
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': data.access_token, //accessToken
    'Token': data.id_token, //idToken
  };
  return callApiUrl('POST', url, {}, headers)
}

const previewLogin = (token) => {
  const url = env.API_URL + "/" + "auth/preview-login";
  const headers = {
    'Content-Type': 'application/json',
  };
  return callApiUrl('POST', url, { token }, headers)
}

const logout = () =>
// remove user from local storage to log user out
// localStorage.removeItem('user');
  callApi('POST', 'auth/sign-out');

const resumeLogin = () =>
  // console.log("resumeLogin ");
  callApi('POST', 'auth/resume-login');

const refreshToken = () =>
  // console.log("refreshToken ");
  callApi('POST', 'auth/refresh-token');



export const accountService = {
  oidcLogin,
  previewLogin,
  logout,
  resumeLogin,
  refreshToken,
};
