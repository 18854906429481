<script setup>
import { ref, onMounted, watch, reactive, nextTick, toRef } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const home = { icon: 'pi pi-home', to: '/dashboard' }

const props = defineProps({
  //autoBuild: {type: Boolean, default: true }, //auto build from router's meta
	items: { type: Array, default: [] },
})

//{ label: "test page", to:"/" }

const rData = reactive({
  loading: false,
	data: [],
  autoBuild: true,
});

onMounted(async() => {
  await nextTick()
});

const buildBreadcrumb = async() => {
  if (props.items.length > 0) {
    rData.loading = true;
    rData.autoBuild = false;
    rData.data = props.items;
    await nextTick();
    rData.loading = true;
  }
};

watch(() => props.items, async () => { buildBreadcrumb(); }, { immediate: true } );

const autoBuildBreadcrumb = async() => {
  if (!rData.autoBuild) return;

  rData.loading = true;
  const arr = [];
  for (var i in route.matched) {
    const r = route.matched[i];
    //console.log("rebuildBreadcrumb", i, r)

    if (r.meta.breadcrumb) {

      const b = { ...r.meta.breadcrumb }; //clone obj
      
      for (var key in route.params) {
        if( key == "id"){
          b.label = b.label.replace(':id', route.params[key]);
          b.to = b.to.replace(':id', route.params[key]);
        }

        if( key == "pk"){
          b.label = b.label.replace(':pk', route.params[key]);
          b.to = b.to.replace(':pk', encodeURIComponent(route.params[key]));
        }
        if( key == "sk"){
          b.label = b.label.replace(':sk', route.params[key]);
          b.to = b.to.replace(':sk', encodeURIComponent(route.params[key]));
        }
        
      }
      arr.push(b);
    }
  }
  rData.data = arr;

  await nextTick();
  rData.loading = true;
};
watch(() => route.fullPath, async () => { autoBuildBreadcrumb(); }, { immediate: true } );
// const items = ref([{label: 'Digital Identity', to: '/digital-identity',}]);
</script>

<template>
  <div class="flex justify-content-start">
    <Breadcrumb :home="home" :model="rData.data" class="pt-2">
      <template #item="{ item, props }">
          <router-link v-if="item.to" v-slot="{ href, navigate }" :to="item.to" custom>
              <a class=" hover:text-500 text-base" :href="href" v-bind="props.action" @click="navigate">
                  <span :class="[item.icon, 'mr-1']" />
                  <span class="mr-1">{{ item.label }}</span>
              </a>
          </router-link>
      </template>
    </Breadcrumb>
  </div>
</template>

<style scoped lang="scss">
.p-breadcrumb {
  background: transparent;
  border: 0;
}
</style>
