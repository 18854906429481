<template>
	<div class="logo-holder">
		<a href="javascript:;" @click="onClickLogo"><i class="ico logo-m360 logo block" :class="logoStyle"></i></a>
	</div>
</template>

<script setup>
import {computed} from 'vue';

//company length check
import { useStoreCompany } from "@/stores/store.company"
import router from "@/router"
const storeCompany = useStoreCompany();

  const props = defineProps({
    size: {type:String}
  })

  const logoStyle = computed(() => ({
    'logo-big' : props.size === 'big'
  }))

  const onClickLogo = () => {
    if(storeCompany.companies.length){
      window.location.href = "/"
    }else{
      router.push("/start-incorporate")
    }
  }
</script>

<style scoped lang="scss">
.logo-holder {
  .logo {
    width: 100px;
    height: 36.98px;

    &.logo-big {
      width: 140px;
      height: 51.778px;
    }
  }
}
</style>
