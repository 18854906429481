import { callApi, callApiUrl } from '@/services/WebApi';
import axios from 'axios';
import env from '@/helpers/Environment';

const getApiVersion = () => callApiUrl('GET', env.BASE_URL);

// const getUploadPresignedUrl = (mt) => callApi('GET', 'site/get-presigned-url/'+mt);
const getUploadPresignedUrl = (mt, fn) => {
  //mimeType, fileName
  let query = new URLSearchParams({
    mimeType:mt,
    fileName:fn
  }).toString();
  return callApi('GET', 'site/get-presigned-url?'+query)
};

const uploadS3 = async (data, mimeType="image/jpeg") => {
  const r = await getUploadPresignedUrl(mimeType, data.upload.filename);
  const { uploadUrl } = r.data;

  const headers = {
    //'Content-Type': 'image/jpeg',
    'Content-Type': mimeType,
    // 'Content-Length': data.size,
  };

  const cancelable = false;
  const withCredentials = false;
  try {
  await callApiUrl('PUT', uploadUrl, data, headers, cancelable, withCredentials);
  } catch(e) {
    console.log(e)
  }
  return r.data;
};


const getPreviewFile = (key) => {
  return callApi('GET', 'site/preview/' + key, true, true, true);
}


export const siteService = {
  getApiVersion,
  getUploadPresignedUrl,
  uploadS3,
  getPreviewFile,
};
