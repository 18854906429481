<script setup>
import { computed } from "vue"

const props = defineProps({
	disabled: { type: Boolean, default: false }
})

const cardClass = computed(() => ({
	disabled: props.disabled,
}))
</script>

<template>
	<div class="card-custom">
		<slot name="content"></slot>
	</div>
</template>

<style scoped lang="scss">
.card-custom {
	// height: 100%;
	border-radius: 0.5rem;
	border: 1px solid #d3d6e9;
	background: #fff;
	padding:1rem;
	box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.04);
	transition: all 0.2s ease;
	&:hover {
		// transform: scale(1.03);
		// background-color: var(--surface-50);
	}
}
</style>




<!-- <script setup>
const props = defineProps({
	header: { type: String, default: "" },
	tooltip: { type: String, default: "" },
	route: { type: String, default: "" },
})
</script>

<template>
	<div class="card-custom">
		<h6 v-if="props.header" class="mb-3 flex justify-content-between align-items-center">
			<span class="flex font-medium text-lg align-items-center gap-2">
				{{ props.header }}
				<i v-if="props.tooltip" class="pi pi-info-circle info-circle" v-tooltip="`${props.tooltip}`"></i>
			</span>

			<router-link v-if="props.route" :to="props.route" class="card-link font-normal text-base no-underline">
				View All
			</router-link>
		</h6>
		<slot></slot>
	</div>
</template>

<style scoped lang="scss">
.card-custom {
	border-radius: 0.5rem;
	border: var(--card-border);
	padding: 1rem;
	background-color: var(--card-bg-color);
}
</style> -->
