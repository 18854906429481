<script setup>
import { useStoreSite } from "@/stores/store.site"
import { useStoreCompany } from "@/stores/store.company"
import Message from 'primevue/message';
import env from '@/helpers/Environment'

import Navbar from "@/views/_layout/_NavBar.vue"
import NavSidebar from "@/views/_layout/_NavSideBar.vue"
import Footer from "@/views/_layout/_Footer.vue"

const storeSite = useStoreSite()
const storeCompany = useStoreCompany()


const onClickTransferToM360 = (item) => {
	const url = env.CENTRAL_AUTH_URL + "?redirect=corpsec-transfer-in-company";
	window.open(url, "_blank")
}


</script>

<template>
	<div class="body-holder flex flex-row">
		<Navbar class="fixed top-0 left-0" />
		<div class="flex-none nav-side" :class="[{ 'sidebar-expand': storeSite.sideBarFlag, 'z-5': storeSite.isMobile }]">
			<div class="nav-side-fixed-bg" id="sidebar-wrapper"></div>
			<NavSidebar class="nav-side-bar" id="sidebar" />
			<Sidebar :pt="{
				mask:{class:'z-4',}
			}" v-if="storeSite.isMobile"  v-model:visible="storeSite.sideBarFlag" header=" "  :showCloseIcon="false" class="w-15rem">
				<NavSidebar class="nav-side-bar" id="sidebar" />
			</Sidebar>
		</div>

		<main class="flex-grow-1 bg-white content-spacing-adjustment">
	
			<div class="container-small">
				<Message v-if="!storeCompany.isClientOfCorpsec()" severity="warn" class="mt-4" :closable="false">
					<template #messageicon>&nbsp;</template>
					<div class="text-sm">Appoint MEYZER360 as your company secretary and discover our tools to supercharge your business.</div>
					<div class="mt-3 text-sm">Click <a href="#" @click.prevent="onClickTransferToM360()">here</a> to transfer to MEYZER360.</div>
				</Message>	

				<router-view v-slot="{ Component }">
					<component :is="Component" />
				</router-view>
			</div>
		</main>
	</div>

	<Footer class="" />
</template>

<style lang="scss" scoped>
.content-spacing-adjustment {
	padding-top: calc(var(--navbar-height) + 1.5rem);
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 30px;

	@media screen and (min-width: $break-medium) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

.nav-side-fixed-bg {
	position: fixed;
	background-color: var(--sidebar-bg-color, #fcfcfd);
	left: 0;
	min-height: 100vh;
	width: calc((100vw - 1100px) / 2);
}

.body-holder {
	max-width: 1650px;
	min-height: calc(100vh - 48px);
	margin: 0 auto;
	background-color: var(--sidebar-bg-color, #fcfcfd);

	//NOTE:Stripe background-color
	// background-image: linear-gradient(270deg, rgb(249, 250, 252), white 100px);

	.nav-side {
		position: relative;
		width: 264px;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		padding-top: 10px;
		transition: width 0.2s ease, min-width 0.2s ease, margin-left 0.2s ease;

		.nav-side-bar {
			top: calc(var(--navbar-height) + 24px);
			position: sticky;
			overscroll-behavior: none;
		}

		@media screen and (max-width: 820px) {
			margin-left: -264px;
		}
	}

	.sidebar-expand {
		position: fixed;

		min-height: 100%;
		z-index: 2;

		@media screen and (min-width: 821px) {
			width: 264px;
			position: relative;
		}
	}

	.content-side {
		background-color: #fff;
	}
}

.wrapper {
	min-height: calc(100vh - 100px);
}

.content {
	&.content-shrink {
		margin-left: 314px;
		transition: all 0.2s ease;
	}
	&.content-expand {
		margin-left: 0;
		transition: all 0.2s ease;
	}

	@media (max-width: $break-small) {
		margin-left: 0;
		padding-top: 1.8rem;
	}
}
</style>
