<template>
	<Dropdown class="w-full" v-model="model" filter :showClear="false"> </Dropdown>
</template>

<script setup>
import Dropdown from "primevue/dropdown"
import { computed } from "vue"

const props = defineProps({
	modelValue: { type: [String, Number], default: "" },
	pLabel: { type: [String, Number], default: "" },
})

const emit = defineEmits(["update:modelValue"])

const model = computed({
	get() {
		return props.modelValue
	},

	set(value) {
		return emit("update:modelValue", value)
	},
})
</script>

<style scoped lang="scss">
.p-dropdown-items-wrapper .p-dropdown-items {
	max-width: 80vw !important;
}

.p-dropdown {
	border: 1px solid var(--surface-200);
	border-bottom: 1px solid var(--surface-500);
	height: 58px;
	align-items: center;

	:deep(.p-dropdown-label.p-placeholder) {
		color: var(--surface-400);
	}

	:deep(.p-dropdown-trigger) {
		padding-right:8px;
	}
}
</style>
