import _ from "lodash";
import { customJoi as Joi } from "@/models/customJoi";

export const mobileCountryCodeTypes = ["+65", "+60", "+86"];

const formMobileFull = Joi.string()
  .forbidden()
  .default((parent, helpers) => {
    return parent.mobileCountryCode + parent.mobile;
  });

export const initSignInMobileForm = {
  //mobileCountryCode: Joi.valid(...mobileCountryCodeTypes).default(mobileCountryCodeTypes[0]),
  mobileCountryCode: Joi.string().default(mobileCountryCodeTypes[0]),
  mobile: Joi.string()
    .pattern(/^[0-9]+$/)
    .required()
    .messages({
      "string.pattern.base": `"mobile" should be a numeric`,
    })
    .min(8)
    .max(20),
  mobileFull: formMobileFull,
};

export const otpForm = {
  username: Joi.string().required(),
  session: Joi.string().required(),
  otp: Joi.string()
    .pattern(/^[0-9]+$/)
    .required()
    .messages({
      "string.pattern.base": `"mobile" should be a numeric`,
    })
    .min(6)
    .max(6),
};
