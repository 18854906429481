<template>
	<Dropdown class="w-full" v-model="model" :options="computeCountryVal" optionLabel="name" optionValue="value"  filter :showClear="false"> 
        <template #option="slotProps">
            <div class="flex gap-3">
                <flag :iso="slotProps.option.iso" class="flag" />
                <!-- <flag iso="sg" class="flag" /> -->
                {{ slotProps.option.name }}
            </div>
        </template>
    </Dropdown>
</template>

<script setup>
import Dropdown from "primevue/dropdown"
import { computed } from "vue"
import { countriesData } from '@/assets/json/all-country.js';

const props = defineProps({
	modelValue: { type: [String, Number], default: "" },
})
const computeCountryVal = computed(()=>{
    return countriesData.map(item => {
        return {
            name:item.name,
            value:item.name.toLowerCase().replace(/\(.*?\)/g, '').trim(),
			iso:item.iso
        }
    })
})

const emit = defineEmits(["update:modelValue"])

const model = computed({
	get() {
		return props.modelValue
	},

	set(value) {
		return emit("update:modelValue", value)
	},
})
</script>

<style scoped lang="scss">
.p-dropdown-items-wrapper .p-dropdown-items {
	max-width: 80vw !important;
}

.p-dropdown {
	border: 1px solid var(--surface-200);
	border-bottom: 1px solid var(--surface-500);
	height: 58px;
	align-items: center;

	:deep(.p-dropdown-label.p-placeholder) {
		color: var(--surface-400);
	}

	:deep(.p-dropdown-trigger) {
		padding-right:8px;
	}
}
</style>
