<script setup>
import { computed } from 'vue';
import Tag from 'primevue/tag'

const props = defineProps({
  value: { type: String, default: 'tag' },
  status: { type: String, default: '' }
})

const tagClass = computed(() => ({
  'p-tag-warning': props.status == 'warning',
  'p-tag-info': props.status == 'info',
  'p-tag-success': props.status == 'success',
  'p-tag-muted': props.status == 'muted',
  'p-tag-error': props.status == 'error' || props.status == 'rejected'
}))
</script>

<template>
  <Tag :value="props.value" :class="tagClass"></Tag>
</template>

<style scoped lang="scss">
.p-tag {
  padding: 0.25rem;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  border-radius: 0.25rem;
  padding-left:0.6rem;
  padding-right:0.6rem;
  line-height: 0.975rem; /* 130% */

  &.p-tag-warning {
    background-color: var(--tag-warning-bg-color);
    color: var(--text-heading, #404040);
  }
  &.p-tag-info {
    background: rgba(15, 98, 230, 0.1);
    color: var(--text-heading, #0f62e6);
  }
  &.p-tag-success {
    color: var(--tag-success-color);
    background: var(--tag-success-bg-color);
  }
  &.p-tag-muted {
    color: #404040;
    background-color: #e1e2e6;
    border: 1px solid #e1e2e6;
    // border: 1px solid var(--grey-grey-400, #BDBDBD);
  }
  &.p-tag-error {
    background-color: red;
    color: white;
  }
}
</style>
