import env from '@/helpers/Environment';
import { callApi } from '@/services/WebApi';

//loynote: to fucking tidying up service.request

export const getAllCompany = (data) => {
  return callApi('GET', `companies`, data);
};

export const getCompany = (id) => {
  return callApi('GET', `companies/`+id, {});
};

export const submitOnboardData = (id, data) => {
  return callApi('PUT', `companies/drafts/${id}`, data);
};

export const resendKYC = (id, data) => {
  return callApi('PUT', `companies/drafts/${id}/resend-kyc`, data);
};

export const resendKYCDirector = (id, data) => {
  return callApi('PUT', `companies/drafts/${id}/resend-kyc/director`, data);
};

export const resendKYCShareholder = (id, data) => {
  return callApi('PUT', `companies/drafts/${id}/resend-kyc/shareholder`, data);
};

export const getDashboardTimeline = (id, data) => {
  return callApi('GET', `events?${id}`, data);
};

export const getDocuments = (companyId) => {
  return callApi('GET', `documents/company/${companyId}`);
}

export const getRequests = (companyId) => {
  return callApi('GET', `requests/company/${companyId}`);
}

export const getEvents = (companyId) => {
  return callApi('GET', `events/company/${companyId}`);
}


export const previewCompanyDocument = (key, sessionAccessToken) => {
  return callApi('GET', 'site/preview/'+key);
}

export const getDocumentById = (companyId, documentId) => {
  return callApi('GET', `documents/${documentId}?`)
} 