<script setup>
import { computed } from "vue"
import Avatar from "primevue/avatar"

const props = defineProps({
	pAvatarUrl: { type: String },
	pSize: { type: String, default: "" },
	pName: { type: String, default: "" },
})

/*** states ***/
const avatarStyle = computed(() => {
	if (props.pSize === "small") return { width: "32px", height: "32px" }
	else if (props.pSize === "mid") return { width: "40px", height: "40px" }
	else if (props.pSize === "xlarge") return { width: "120px", height: "120px" }
})
const pic = computed(() => {
	return props.pAvatarUrl
})
const firstLetter = computed(() => {
	if (!pic.value && props.pName) {
		return props.pName[0].toUpperCase()
	}
})
</script>

<template>
	<Avatar
		:pt="{
			root: { style: avatarStyle },
		}"
		:label="firstLetter"
		:image="pic"
		:size="pSize"
		shape="circle" />
</template>

<style scoped>
.p-avatar {
	background-color: transparent;
}
:deep(img) {
	object-fit: cover;
}
</style>
